export const BANK_TYPES = {
  en_ligne: 'En ligne',
  nationale: 'Nationale',
  regionale: 'Régionale',
}

export const OPTIONS = {
  account_fees: 'Frais annuels de tenue de compte (en moyenne)',
  application_fees: 'Frais de dossier',
  financing_works: 'Financement de travaux possible',
  fixed_rate: "Taux d'intérêt fixe",
  guarantee: 'Organisme de caution',
  hypotheque: 'Hypothèque possible',
  ippd: 'IPPD possible',
  scoring_ira: 'Exonération d’IRA',
  scoring_modularity: 'Flexibilité de la modularité',
  primary_residence: 'Résidence principale',
  rental_investment: 'Investissement locatif',
  scoring_report_deadline: 'Flexibilité de la suspension d’échéances',
  repurchase: 'Renégociation',
  secondary_residence: 'Résidence secondaire',
}
export const DESCRIPTIONS = {
  application_fees: 'À titre indicatif, peut varier en fonction de chaque agence et selon votre profil',
  scoring_ira: 'ira_description',
  scoring_report_deadline: 'report_deadline_description',
  scoring_modularity: 'modularity_description',
}

export const BANK_FEES_SECTION = {
  keys: ['application_fees', 'account_fees', 'fixed_rate'],
  title: 'Frais bancaires',
}
export const REFUND_SECTION = {
  keys: ['scoring_modularity', 'scoring_report_deadline', 'scoring_ira'],
  title: 'Remboursement de prêt immobilier',
  type: 'score'
}
export const PROJECT_TYPE_SECTION = {
  keys: ['primary_residence', 'secondary_residence', 'rental_investment', 'repurchase', 'financing_works'],
  title: 'Type de projet',
}
export const GUARANTEE_SECTION = { keys: ['guarantee', 'ippd', 'hypotheque'], title: 'Organisme de caution' }
