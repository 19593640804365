import { g } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'

import { Check, ChevronDownBold, Times } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const TableRow = styled.div`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.neutral4};
  }
`
export const RowTitle = styled(BaseTypo).attrs({ typo: 'bodyBold16' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${g(2)} ${g(4)};
  cursor: pointer;
`
export const Arrow = styled(ChevronDownBold)`
  width: ${g(3)};
  height: ${g(3)};
  transition: transform 300ms ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotateZ(180deg);
    `}
`
export const Options = styled.ul`
  overflow: hidden;
  transition: height 300ms ease-in-out;
`
export const Option = styled.li`
  padding: ${g(2)} ${g(4)};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
export const Bullet = styled.svg.attrs(({ $isChecked }) => ({ as: $isChecked ? Check : Times }))`
  color: ${({ $isChecked, theme: { colors } }) => ($isChecked ? colors.primary1 : colors.error1)};
  margin-right: ${g(2)};
  width: ${g(2)};
  height: ${g(2)};
`
export const Description = styled(BaseTypo).attrs({ typo: 'caption12' })`
  color: ${({ theme }) => theme.colors.neutral2};
  flex: 0 0 100%;
  padding-left: ${g(4)};
`
