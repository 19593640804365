import { ENTER, SPACE } from '@pretto/bricks/core/constants/keycodes'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import * as S from './styles'

const TableRow = ({ options, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [initialHeight, setInitialHeight] = useState(0)
  const [optionsHeight, setOptionsHeight] = useState('auto')
  const optionsRef = useRef(null)

  useEffect(() => {
    setInitialHeight(optionsRef.current.clientHeight)
  }, [])

  useEffect(() => {
    if (isOpen) {
      setOptionsHeight(initialHeight)
      return
    }

    setOptionsHeight(0)
  }, [isOpen])

  const handleToggle = () => {
    setIsOpen(isOpen => !isOpen)
  }

  const handleKeyDown = event => {
    if ([SPACE, ENTER].includes(event.keyCode)) {
      event.preventDefault()
      handleToggle()
    }
  }

  return (
    <S.TableRow>
      <S.RowTitle onClick={handleToggle} onKeyDown={handleKeyDown} tabIndex="0" aria-expanded={isOpen}>
        {title} <S.Arrow $isOpen={isOpen} />
      </S.RowTitle>
      <S.Options ref={optionsRef} style={{ height: optionsHeight }}>
        {options.map(({ description, label, type, value }, i) => (
          <S.Option key={i}>
            <S.Bullet $isChecked={!!value || value === 0} /> {label}
            {type === 'number' && (
              <>
                 :&nbsp;<strong>{formatNumber(value, { suffix: '€' })}</strong>
              </>
            )}
            {type === 'score' && value && (
              <>
                 :&nbsp;<strong>{value}/5</strong>
              </>
            )}
            {description && <S.Description>{description}</S.Description>}
          </S.Option>
        ))}
      </S.Options>
    </S.TableRow>
  )
}

TableRow.propTypes = {
  /** Options of row */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  /** Title of row. */
  title: PropTypes.string.isRequired,
}

export default TableRow
