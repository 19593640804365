import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { getBankLogo } from '@pretto/app-core/lib/getBankLogo'

import PropTypes from 'prop-types'
import React, { memo } from 'react'

const LogoBank = ({ format, height, slug, ...props }) => {
  const path = getBankLogo({ slug, format })
  const options = height ? { height } : {}
  const src = getCloudinaryUrl(path, options)

  return <img alt={slug} loading="lazy" src={src} {...props} />
}

LogoBank.propTypes = {
  /** Format of logo. */
  format: PropTypes.oneOf(['dark', 'light', 'regular', 'squared']),
  /** Height of the logo. */
  height: PropTypes.number,
  /** Slug of bank used to define which logo to display. */
  slug: PropTypes.string.isRequired,
}

export default memo(LogoBank)
