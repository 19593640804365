import { DESCRIPTIONS, OPTIONS } from '../config/config'

export const formatOptions = (keys, data, type) => 
  keys.map(key => {
    const label = OPTIONS[key]
    const description = (DESCRIPTIONS[key] in data) ? data[DESCRIPTIONS[key]] : DESCRIPTIONS[key]
    const value = data[key]
    return {
      description,
      label,
      type: type ?? typeof value,
      value,
    }
  })
