import BankData from '@pretto/bricks/website/bank/components/BankData'
import SimulationBankPage from '@pretto/bricks/website/bank/pages/SimulationBank'

import { Building, Marker, Star } from '@pretto/picto'
import { formatOptions } from '@pretto/website/src/templates/simulateurBanque/lib/formatOptions'
import { SimulateurBanque as SimulateurBanqueView } from '@pretto/website/src/templates/simulateurBanque/views/SimulateurBanque/SimulateurBanque'
import { useSentences } from '@pretto/website/src/utilities'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { BANK_FEES_SECTION, BANK_TYPES, GUARANTEE_SECTION, PROJECT_TYPE_SECTION, REFUND_SECTION } from './config/config'

export const SimulateurBanque = ({ bankGateway, data, ...props }) => {
  const {
    template: {
      simulationBank: { imageBankSlug, headline, tableTitle },
    },
    title,
  } = data

  const bankData = bankGateway.airtable.banks[0]

  const miniSentencesProps = useSentences({ tabs: ['purchase', 'renegotiation'] })

  const { number_branches, bankin_grade, type } = bankData

  const sections = [BANK_FEES_SECTION, REFUND_SECTION, PROJECT_TYPE_SECTION, GUARANTEE_SECTION].map(
    ({ keys, title, type }) => ({
      options: formatOptions(keys, bankData, type),
      title,
    })
  )

  const bankDataProps = {
    data: sections,
    title: tableTitle,
  }

  const reasonToBelieveProps = [
    bankData.score && {
      infoDescription:
        "Correspond à la moyenne des notes d'expérience de financement, frais bancaires et communauté Bankin",
      infoTitle: 'Avis clients :',
      infoValue: (
        <>
          {bankData.score.toLocaleString('fr-FR')}&nbsp;<span style={{ fontWeight: 'normal' }}>/5</span>
        </>
      ),
      picto: Star,
    },
    bankData.number_branches && {
      infoDescription: "Correspond au nombre d'agences physique de la banque en France métropolitaine",
      infoTitle: 'Nombre d’agences :',
      infoValue: bankData.number_branches,
      picto: Marker,
    },
    bankData.type && {
      infoDescription: "Précise si la banque est composée d'agences physiques ou est uniquement en ligne",
      infoTitle: 'Type de banque :',
      infoValue: bankData.type === 'en_ligne' ? 'En ligne' : 'Physique',
      picto: Building,
    },
  ].filter(Boolean)

  const simulationBankPageProps = useMemo(
    () => ({
      bankinRating: bankin_grade,
      headline,
      miniSentencesProps,
      numberOfAgencies: type === 'en_ligne' ? null : number_branches,
      slug: imageBankSlug,
      title,
      type: BANK_TYPES[type],
      reasonToBelieveProps,
    }),
    [miniSentencesProps]
  )

  const headerComponent = useMemo(() => <SimulationBankPage {...simulationBankPageProps} />, [simulationBankPageProps])

  const bankDetailsComponent = useMemo(() => <BankData {...bankDataProps} />, [])

  return (
    <SimulateurBanqueView {...props} bankDetailsComponent={bankDetailsComponent} headerComponent={headerComponent} />
  )
}

SimulateurBanque.propTypes = {
  bankGateway: PropTypes.shape({
    airtable: PropTypes.shape({
      banks: PropTypes.shape({
        score: PropTypes.number,
        number_branches: PropTypes.number,
      }),
    }),
  }).isRequired,
  data: PropTypes.shape({
    template: PropTypes.shape({
      simulationBank: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        imageBankSlug: PropTypes.string.isRequired,
        tableTitle: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment SimulateurBanqueTemplate on WpTemplate_SimulateurParBanque {
    simulationBank {
      imageBankSlug: imagebankslug
      headline
      tableTitle: tabletitle
    }
  }
`
