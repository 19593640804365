import { Grid } from '@pretto/bricks/components/layout/Grid'

import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './SimulateurBanque.styles'

export const SimulateurBanque = ({ bankDetailsComponent, headerComponent }) => {
  const { contentWithSidebarComponent, isSidebarMenuActive } = useContext(PostContentContext)

  return (
    <S.Page>
      <S.Header>{headerComponent}</S.Header>

      <Grid>
        <S.Content $isSidebarMenuActive={isSidebarMenuActive}>
          {bankDetailsComponent}
          {contentWithSidebarComponent}
        </S.Content>
      </Grid>
    </S.Page>
  )
}

SimulateurBanque.propTypes = {
  bankDetailsComponent: PropTypes.node.isRequired,
  headerComponent: PropTypes.node.isRequired,
}
