import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'

import PropTypes from 'prop-types'

import * as S from './styles'

const SimulationBank = ({ headline, miniSentencesProps, reasonToBelieveProps, slug, title, ...props }) => {
  const hasReasonToBelieve = reasonToBelieveProps.length > 0

  return (
    <S.SimulationBank {...props}>
      <S.HeaderTop $hasReasonToBelieve={hasReasonToBelieve}>
        <S.Title>{title}</S.Title>
        <S.Headline>{headline}</S.Headline>
        <S.Widget {...miniSentencesProps} />

        <S.BankContainer>
          <ResponsiveSSR max="tablet">
            <S.Bank slug={slug} height={60} />
          </ResponsiveSSR>
          <ResponsiveSSR min="tablet">
            <S.Bank slug={slug} format="light" height={60} />
          </ResponsiveSSR>
        </S.BankContainer>
      </S.HeaderTop>

      {hasReasonToBelieve && (
        <S.ReasonToBelieveList {...props}>
          {reasonToBelieveProps.map(promesseProps => (
            <S.ReasonToBelieve {...promesseProps} key={promesseProps.title} />
          ))}
        </S.ReasonToBelieveList>
      )}
    </S.SimulationBank>
  )
}

SimulationBank.propTypes = {
  /** Text below title. */
  headline: PropTypes.string.isRequired,
  /** Props of [`MiniSentences`](/#!/MiniSentences) component. */
  miniSentencesProps: PropTypes.object.isRequired,
  /** ReasonToBelieve props */
  reasonToBelieveProps: PropTypes.array,
  /** Slug of bank, used for [`LogoBank`](/#!/LogoBank) component. */
  slug: PropTypes.string.isRequired,
  /** Main title of component. */
  title: PropTypes.string.isRequired,
}

export default SimulationBank
