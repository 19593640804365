import TableRow from '@pretto/bricks/website/bank/components/TableRow'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BankData = ({ title, data }) => (
  <S.BankData>
    <S.Title>{title}</S.Title>
    <S.Table>
      {data.map((props, i) => (
        <TableRow key={i} {...props} />
      ))}
    </S.Table>
  </S.BankData>
)

BankData.propTypes = {
  /** Rows to be displayed. */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          label: PropTypes.string.isRequired,
          type: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
        })
      ).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Title of element */
  title: PropTypes.string.isRequired,
}

export default memo(BankData)
