import { g } from '@pretto/bricks/components/layout'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import styled from 'styled-components'

export const BankData = styled.div`
  margin-bottom: ${g(4)};
`
// SubHeading (deprecated) is used in order to match website content
export const Title = styled(SubHeading).attrs({ size: 'large', type: 'h2' })`
  margin-bottom: ${g(4)};
`
export const Table = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
`
